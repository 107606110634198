var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { width: "100%" } },
    [
      _c(
        "el-table",
        {
          staticStyle: { width: "100%" },
          attrs: { border: "", size: "mini", data: _vm.datas }
        },
        [
          _c("el-table-column", {
            attrs: {
              fixed: "left",
              prop: "Code",
              label: "客户编号",
              "min-width": "120"
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "Name",
              fixed: "left",
              label: "姓名",
              "min-width": "180"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(" " + _vm._s(scope.row.RealName)),
                    _c("el-tag", { attrs: { type: "warning" } }, [
                      _vm._v("@" + _vm._s(scope.row.Actor.Name))
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "Mobile",
              fixed: "left",
              label: "手机号",
              "min-width": "100"
            }
          }),
          _c("el-table-column", {
            attrs: { prop: "Sex", label: "性别", width: "60" }
          }),
          _c("el-table-column", {
            attrs: { prop: "Birthday", label: "年龄", width: "60" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.format.getAgeByDateString(
                            _vm.format.dateFormat(scope.row.Birthday)
                          )
                        )
                      )
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { prop: "IdCode", label: "身份证号", width: "150" }
          }),
          _c("el-table-column", {
            attrs: { prop: "Height", label: "身高", width: "60" }
          }),
          _c("el-table-column", {
            attrs: { prop: "Weight", label: "体重", width: "60" }
          }),
          _c("el-table-column", {
            attrs: { prop: "ShoesSize", label: "鞋码", width: "100" }
          }),
          _c("el-table-column", {
            attrs: { prop: "BMI", label: "BMI", width: "60" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [_vm._v(" " + _vm._s(scope.row.BMI.toFixed(2)) + " ")]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { prop: "FatRate", label: "体脂率", width: "100" }
          }),
          _c("el-table-column", {
            attrs: { prop: "BloodPressureMin", label: "血压", width: "120" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(scope.row.BloodPressureMin) +
                        "- " +
                        _vm._s(scope.row.BloodPressureMax) +
                        " "
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "InsertTime",
              label: "注册时间",
              "min-width": "100"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(_vm.format.dateFormat(scope.row.InsertTime)) +
                        " "
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "CheckSelfHeart.AcceptableStatus",
              label: "心血管筛查结果",
              "min-width": "120"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.CheckSelfHeart.AcceptableStatus == 0
                      ? _c("el-tag", { attrs: { type: "info" } }, [
                          _vm._v("未评估")
                        ])
                      : _vm._e(),
                    scope.row.CheckSelfHeart.AcceptableStatus == 10
                      ? _c("el-tag", { attrs: { type: "success" } }, [
                          _vm._v("低危")
                        ])
                      : _vm._e(),
                    scope.row.CheckSelfHeart.AcceptableStatus == 20
                      ? _c("el-tag", { attrs: { type: "danger" } }, [
                          _vm._v("高危")
                        ])
                      : _vm._e()
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "CheckSelfIKDC.IKDCScore",
              label: "IKDC自测结果",
              "min-width": "150"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.CheckSelfIKDC && scope.row.CheckSelfIKDC.Key
                      ? _c("el-tag", { attrs: { type: "warning" } }, [
                          _vm._v(
                            _vm._s(scope.row.CheckSelfIKDC.IKDCScore) +
                              "/" +
                              _vm._s(scope.row.CheckSelfIKDC.IKDCResult)
                          )
                        ])
                      : _c("el-tag", { attrs: { type: "info" } }, [
                          _vm._v("未评估")
                        ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "CheckSelfVAS.Count",
              label: "VAS自测结果",
              "min-width": "120"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.CheckSelfVAS && scope.row.CheckSelfVAS.Key
                      ? _c("el-tag", { attrs: { type: "warning" } }, [
                          _vm._v(
                            _vm._s(scope.row.CheckSelfVAS.Count) + "处疼痛"
                          )
                        ])
                      : _c("el-tag", { attrs: { type: "info" } }, [
                          _vm._v("未评估")
                        ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              fixed: "right",
              width: 160,
              label: "操作",
              align: "center"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function($event) {
                            return _vm.update(scope.row)
                          }
                        }
                      },
                      [_vm._v("修改")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function($event) {
                            return _vm.showAnswner(scope.row)
                          }
                        }
                      },
                      [_vm._v("查看问卷")]
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c("el-pagination", {
        staticClass: "pagination",
        attrs: {
          background: "",
          "page-sizes": _vm.pageSizeList,
          "page-size": _vm.pageSize,
          layout: "total, sizes, prev, pager, next, jumper",
          total: _vm.total,
          "current-page": _vm.pageIndex
        },
        on: {
          "size-change": _vm.pageSizeChange,
          "current-change": _vm.pageCurrentChange,
          "update:currentPage": function($event) {
            _vm.pageIndex = $event
          },
          "update:current-page": function($event) {
            _vm.pageIndex = $event
          }
        }
      }),
      _c("customerForm", { ref: "customerForm", on: { update: _vm.getData } }),
      _c("answerViewer", { ref: "answerViewer" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }